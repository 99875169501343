import React, { useContext } from 'react';
import i18n from "i18next";
import Slider from "react-slick";
import { LoadingContext } from '../../components/context/LoadingContext';
import image1 from '../../images/exsisAcademy/imageBootcamp.png'
import image2 from '../../images/exsisAcademy/imageBootcamp2.png'
import { Link } from "gatsby"

const Bootcamps = ({ div }) => {

    const { windowWidth } = useContext(LoadingContext);


    

    return (
        <div className={`desktop:p-20 p-6  ${div}`}>
             {windowWidth >= 1025 ? 
             <div className="flex w-full">
                  
                    <div className="contentWhyUs divInfoExsisAcademy mb-8">
                    <div className="eventRightTimeLineMobile">
                    <p className='titleInfoExsisAcademy'>{i18n.t("ExsisAcademy.titleAcademy")}</p> 
                    </div>
                       
                        <p className="textInfoExsisAcademy2 my-8">
                        {i18n.t("ExsisAcademy.textAcademy")}
                          </p>    
                          <p className="textInfoExsisAcademy2">
                          {i18n.t("ExsisAcademy.questionAcademy")}
                          </p>
                          
                       
                          <div className=''>
                          <div className="desktop:text-left">
                                <Link to={"/contact-us"}>
                                <button className="buttonLetsTalk text-18 font-bold ">{i18n.t("ExsisAcademy.botom")}</button>
                                </Link>
                                </div>
                        </div> 
                        
                    </div> 
                    <div className="  mt-10 divInfoExsisAcademyBootcamps flex ">
                       
                       <div className=' '>
                               <img src={image2} alt="...." className=""/>
                           </div>
                           
                       <div className='ml-4 '>
                               <img src={image1} alt="...." className=""/>
                           </div>
                   </div>
                  
                    
                    </div>  :

                        <div className=" w-full">
                                        
                        <div className="mt-8">
                        <div className="eventRightTimeLineMobile">
                        <p className='titleInfoExsisAcademy'>{i18n.t("ExsisAcademy.titleAcademy")}</p> 
                        </div>
                            
                            <p className="textInfoExsisAcademy2Mobile mt-8">
                            {i18n.t("ExsisAcademy.textAcademy")}
                            </p>  
                            <p className="textInfoExsisAcademy2Mobile mt-4">
                            {i18n.t("ExsisAcademy.questionAcademy")}
                            </p> 
                            
                           
                            
                        </div> 
                        <div className="  mt-10 ">
                        
                        <div className=' '>
                                <img src={image2} alt="...." className="w-full"/>
                            </div>
                            
                        <div className='mt-4'>
                                <img src={image1} alt="...." className="w-full"/>
                            </div>
                        </div>

                        <div className=''>
                          <div className="desktop:text-left">
                                <Link to={"/contact-us"}>
                                <button className="buttonLetsTalk text-18 font-bold w-full">{i18n.t("ExsisAcademy.botom")}</button>
                                </Link>
                                </div>
                        </div> 

                        </div> 
                                            
                                            
                                            }                
                                
          
        </div>
    )
}



export default Bootcamps
