import React, { useContext, useRef } from 'react';
import i18n from "i18next";
import { LoadingContext } from '../../components/context/LoadingContext';
import aws from '../../images/exsisAcademy/AWS.png';
import blockchain from '../../images/exsisAcademy/BLOCKCHAIN.png';
import solidity from '../../images/exsisAcademy/SOLIDITY.png';
import expert from '../../images/exsisAcademy/EXPERT.png';
import Slider from "react-slick"


const CertificationsAcademy = ({}) => {

  const { windowWidth } = useContext(LoadingContext);
  const carouselRef = useRef(null)
  const imagesArr = [
    {
        image: aws,
        texto: "AWS Cloud Practioner Essentials",
    },
    {
        image: blockchain,
        texto: "Blockchain",
    },
    {
        image: solidity,
        texto: "Solidity Developer",
    },
    {
        image: expert,
        texto: "Hyperledger Expert",
    },

];

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}
    
  return (
    <div className="mobile:w-full desktop:pl-4 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerCertification">
             
             {windowWidth >= 1025 ? 
              <div className="flip-card-inner2  mt-24 mb-24">
              <div className="mobile:w-full mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 mobile:pr-16">
           
                    <p className="titleCompanies">{i18n.t("ExsisAcademy.titleCerti")}</p>              
        
                  </div>
                  
                  <div className="flex targetExperts mt-12 justifyStyle">
                  <div className='contentTargetCertifications '>
                            <div className='m-auto boxFlex'>
                            <img src={aws} alt="...." className="mr-auto ml-auto mt-4"/>
                            <p className='textCertificationAcademy m-8 mt-4'>AWS Cloud Practioner Essentials</p>
                            </div>
                </div>
                <div className='contentTargetCertifications '>
                            <div className='m-auto boxFlex'>
                            <img src={blockchain} alt="...." className="mr-auto ml-auto mt-4"/>
                            <p className='textCertificationAcademy m-8 mt-4'>Blockchain</p>
                            </div>
                </div>
                <div className='contentTargetCertifications '>
                            <div className='m-auto boxFlex'>
                            <img src={solidity} alt="...." className="mr-auto ml-auto mt-8"/>
                            <p className='textCertificationAcademy m-4 mt-4'>Solidity Developer</p>
                            </div>
                </div>
                <div className='contentTargetCertifications '>
                            <div className='m-auto boxFlex'>
                            <img src={expert} alt="...." className="mr-auto ml-auto mt-8"/>
                            <p className='textCertificationAcademy m-4 mt-4'>Hyperledger Expert</p>
                            </div>
                </div>
              </div>
                   
                             
              </div>:
               <div className="flip-card-inner2  mt-24 mb-24">
               <div className="mobile:w-full mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 ">
            
                     <p className="titleCompanies">{i18n.t("ExsisAcademy.titleCerti")}</p>              
         
                   </div> 
                   
                   <div className="mt-12 justifyStyle">
                   <Slider
                      ref={carouselRef}
                      {...settings}
                      className="rounded-sm  mx-2 py-10"
                    >
                    {imagesArr.map((item, i) => {
                        return (
                    <div className='contentTargetAcademyMobile2 '>
                            <div className='m-auto'>
                            <img src={item.image} alt="...." className={ 'imgCardCertification'}/>
                            <p className='textCertificationAcademy m-4 mt-4'>{item.texto}</p>
                            </div>

                </div>)
                 }) 
                 }
              </Slider>
               </div>
                    
                              
               </div>
              
            }
             
            </div>

  );
};

export default CertificationsAcademy;