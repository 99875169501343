import React, { useState, useEffect, useRef } from "react"
import i18n from "i18next"
import columnImageRight from "../images/exsisAcademy/BannerAcademy.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withNamespaces } from "react-i18next"
import teamEs from "./../components/ourDNA/serviceOurTeam/teamEs"
import teamEn from "./../components/ourDNA/serviceOurTeam/teamEn"
import BannerExsisAcademy from "../components/exsisAcademy/BannerExsisAcademy"
import Bootcamps from "../components/exsisAcademy/Bootcamps"
import CertificationsAcademy from "../components/exsisAcademy/CertificationsAcademy"
import BannerSubscribeAcademy from "../components/exsisAcademy/BannerSubscribeAcademy"
import TestimonialsAcademy from "../components/exsisAcademy/TestimonialsAcademy"

const ExsisAcademy = () => {
  const [descriptionTitleBanner, setDescriptionTitleBanner] = useState([])
  const [youtubeVideoId, setYoutubeVideoId] = useState("")
  const [team, setTeam] = useState(teamEs)

  useEffect(() => {
    if (i18n.language === "en") {
      setTeam(teamEn)
      setYoutubeVideoId("GcDt4A3Xbn4")
      setDescriptionTitleBanner([
        <div className="flex flex-col txt-our-adn txt-our-adn-movil">
          <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-center mb-4 mobile:mx-2 desktop:mx-24">
            <span className="textOurExperience2 flex">
              <p className="colorTextExperience2">E</p>
              <p className="colorTextExperience">x</p>
              <p className="colorTextExperience2">sis</p>
            </span>
            <span className="textOurExperience2">
              <p className="colorTextExperience2">Academy</p>
            </span>
          </h3>
        </div>,
      ])
    } else {
      setTeam(teamEs)
      setYoutubeVideoId("EGpJEq_JRPA")
      setDescriptionTitleBanner([
        <div className="flex flex-col txt-our-adn txt-our-adn-movil">
          <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-center mb-4 mobile:mx-2 desktop:mx-24">
            <span className="textOurExperience2">
              <p className="colorTextExperience2">Academia</p>
            </span>
            <span className="textOurExperience2 flex">
              <p className="colorTextExperience2">E</p>
              <p className="colorTextExperience">x</p>
              <p className="colorTextExperience2">sis</p>
            </span>
          </h3>
        </div>,
      ])
    }
  }, [i18n.language])

  return (
    <Layout>
      <SEO
        title={"Exsis academy | Exsis Digital Angels"}
        description={"Nearshore Agile Teams | Specialists in Tech Talent"}
      />

      <BannerExsisAcademy
        bannerImageURL={columnImageRight}
        bannerImageContent={descriptionTitleBanner}
      ></BannerExsisAcademy>

      <Bootcamps div="whyusdiv" />

      <CertificationsAcademy />
      <BannerSubscribeAcademy />
      <TestimonialsAcademy />
    </Layout>
  )
}

export default withNamespaces()(ExsisAcademy)
