import Miguel from './../../../images/ourDNA/Our Team/MiguelJimenez.jpg';
import Sebastian from './../../../images/ourDNA/Our Team/SebastianJimenez.jpg';
import Santiago from './../../../images/ourDNA/Our Team/SantiagoJimenez.jpg';
import Yuli from './../../../images/ourDNA/Our Team/YuliRios.jpg';


const items = [
	{
		"image": Miguel,
		"name": "Miguel Jiménez",
		"position": "CEO",
		"description": "Exsis business founder, innovator by conviction, generator of new ideas and business models.",
		"linkedIn": "https://www.linkedin.com/in/miguel-angel-60a76a7/"
	},
	{
		"image": Sebastian,
		"name": "Sebastián Jiménez",
		"position": "Country Manager",
		"description": "Developing new possibilities, for the future of the organization.",
		"linkedIn": "https://www.linkedin.com/in/sebastian-jimenez-pedraza-37884093/"
		
	},
	{
		"image": Santiago,
		"name": "Santiago Jiménez",
		"position": "Chief Innovation Offices",
		"description": "Lead, plan and manage the strategy, projects and culture of innovation within the company. In order to create new lines of business or new technological products for the organization.",
		"linkedIn": "https://www.linkedin.com/in/santiago-jim%C3%A9nez-23797616b/"
	},
	{
		"image": Yuli,
		"name": "Yuli Ríos",
		"position": "Public accountant",
		"description": "Ensure the interest of the Company with honesty, protecting and giving good management to resources, provide management with reliable, useful and timely financial information for decision making.",
		"linkedIn": "https://www.linkedin.com/in/andrea-rios-tapiero-2672a3211/"
	},

]
 
export default items;