import React, { useContext, useState } from 'react';
import i18n from "i18next";
import { LoadingContext } from '../../components/context/LoadingContext';
import { sendEmailBootcamp } from "./../../utils/api.js";
import Swal from 'sweetalert2';


const BannerSubscribeAcademy = ({}) => {

  const { windowWidth } = useContext(LoadingContext);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangeEmail = async (event) => {
    setEmail(event.target.value)
  };

  async function sendEmail (){
    setLoading(true)
    const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if(email == ""){
      Swal.fire('', i18n.t("home.alertempty"), '')
      setLoading(false);
    }else{
      if (expression.test(email)) {
        await sendEmailBootcamp(email)
            .then(async (res) => {
              setLoading(false)
              await Swal.fire('', i18n.t("home.alertformsend"), '');
              setEmail("");
              
            })
            .catch((err) => closeError());
      }else{
        setLoading(false);
        Swal.fire('', i18n.t("home.alertwrongemail"), '')
      }
    }
    
  }

  function closeError(){
    setLoading(false);
    Swal.fire('', i18n.t("home.alertformerror"), '');
  }

    
  return (
    <div className="mobile:w-full desktop:pl-8 desktop:pr-4  flip-card bannerAcademySubscribe">
            {  windowWidth >= 1025 ? 
            
            <div className="flip-card-inner2 contentBannerAcademySubscribe  mt-24 mb-24">
              <div className='grid'>
                    
                    <p className='titleBannerAcademySubscribe'>{i18n.t("ExsisAcademy.textSuscribe")}</p> 
                    <input className="inputSubscribeAcademy"  
                    type="text" 
                    placeholder='Enter your email address' 
                    value={email}
                    onChange={(e) => handleChangeEmail(e)}
                    />
					
					
                    <button className={loading == true ? 'mobile:mt-4 contentButtomMoreAcademyLoading boxTransform':'mobile:mt-4 contentButtomMoreAcademy boxTransform'}
                    style={{alignSelf:'center', width: '12rem'}}
                    disabled={loading}
                    onClick={() => sendEmail()}>
                        <p className='textButtomGetTouch colorTextExperience2'>{loading === true ? <i class="fa fa-spinner fa-spin"></i>: i18n.t("ExsisAcademy.botomSuscribe")}</p>
                        </button>
                    </div>
                   
                             
              </div>:
              <div className="flip-card-inner2 mx-4 mt-24 mb-24">
              <div className='grid'>
                    
                    <p className='titleBannerAcademySubscribeMobile'>{i18n.t("ExsisAcademy.textSuscribe")}</p> 
                    <input className="inputSuscribeAcademyMobile"  type="text" placeholder='Enter your email address'
                     value={email}
                     onChange={(e) => handleChangeEmail(e)}
                    />
                    
                    <button className={loading == true ? 'mobile:mt-4 contentButtomMoreAcademyLoading boxTransform':'mobile:mt-4 contentButtomMoreAcademy boxTransform'}
                    style={{alignSelf:'center', width: '100%'}}
                    disabled={loading}
                    onClick={() => sendEmail()}
                    >
                        <p className='textButtomGetTouch colorTextExperience2'>{loading === true ? <i class="fa fa-spinner fa-spin"></i>: i18n.t("ExsisAcademy.botomSuscribe")}</p>
                        </button>
                    </div>
                   
                             
              </div>
              
              }
             
            </div>

  );
};

export default BannerSubscribeAcademy;